import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <h1>Wispr Terms & Privacy Policy</h1>
      <p>Wispr is designed to never collect or store any sensitive information. Wispr messages and calls cannot be accessed by us or other third parties because they are always end-to-end encrypted, private, and secure. Our Terms of Service and Privacy Policy are available below.</p>
      <h2>Terms of Service</h2>
      <p>Wispr(by "CryptoDATA TECH S.R.L.") utilizes state-of-the-art security and end-to-end encryption to provide private messaging, internet calling, and other services to users worldwide. You agree to our Terms of Service (“Terms”) by installing or using our app and services</p>
      <p><b>Account Registration: </b>To create an account you must register for our Services using your phone number. You agree to receive text messages and phone calls (from us or our third-party providers) with verification codes to register for our Services.</p>
      <p><b>Privacy of user data: </b>Wispr does not sell, rent or monetize your personal data or content in any way – ever.</p>
      <p><b>Fees and Taxes: </b>You are responsible for data and mobile carrier fees and taxes associated with the devices on which you use our Services.</p>
      <p><b>Your Rights: </b>You own the information you submit through our Services. You must have the rights to the phone number you use to sign up for your Wispr account.</p>
      <p><b>Availability of Our Services: </b>Our Services may be interrupted, including for maintenance, upgrades, or network or equipment failures. We may discontinue some or all of our Services, including certain features and the support for certain devices and platforms, at any time.</p>
      <p><b></b></p>
      <p><b></b></p>
      <p><b></b></p>
      
      
      <h2>Privacy Policy</h2>
      <p>Wispr utilizes end-to-end encryption to provide private messaging, voice and video calling services to its users. Your calls and messages are always encrypted, so they can never be shared or viewed by anyone but yourself and the intended recipients.</p>
      
      <h3>What information do you need to provide</h3>
      <p><b>Account Creation. </b> To create an account you must register for our Services using your phone number. Phone numbers are stored securely and are used to provide our Services to you and other Wispr users. You agree to receive text messages and phone calls (from us or our third-party providers) with verification codes to register for our Services.</p>
      <p>You own the information you submit through our Services. You must have the rights to the phone number you use to sign up for your Wispr account.</p>
      <p>You may optionally add other information to your account, such as a profile name and profile picture. All optional information is end-to-end encrypted, which means that is only visible to you and your contacts!</p>
      
      <p><b>Messages.</b> When you go offline, Wispr uses queues to keep your messages ready to be delivered to you as soon as you get back online. However these messages are end-to-end encrypted and nobody can see them, but you. Moreover, we don't keep any copies of the encrypted messages, when the encrypted message has arrived to you, it has already been deleted from our servers. This is available for both text and media(images, videos, audio files, etc,) messages</p>
      <p><b>Contacts.</b> Wispr has a feature that, if chosen, can identify which of your address book contacts are also using Wispr. This functionality is built to safeguard the privacy of your contacts. Data from your device's contacts might be converted into cryptographic hashes and sent to the server. This is done to figure out which contacts in your address book are registered with Wispr.</p>
        <h3>Collection and Use of Images</h3>
        <p><b>1. Types of Images Collected:</b> Our application allows users to upload images for use as profile pictures and for sending encrypted media messages to other users. These are the only types of images our app collects.</p>

        <p><b>2. Purpose of Image Collection:</b></p>

        <ul>
            <li>Profile Pictures: Users can upload images to be used as their profile picture. This helps in identifying users within the app.</li>
            <li>Media Messages: Users can send images as part of end-to-end encrypted (E2EE) media messages. These images are encrypted to ensure only the intended recipient can view them.</li>
        </ul>

        <p><b>3. End-to-End Encryption (E2EE):</b></p>

        <p>All media messages, including images, are end-to-end encrypted. This means that they can only be decrypted and viewed by the sender and the intended recipient(s). Our servers store these messages in encrypted form and do not have the capability to decrypt them.</p>
        <p><b>4. Storage of Images:</b></p>

            <ul>
                <li><b>Profile Pictures: </b>These images are stored on our servers to maintain user profiles and are accessible to other users of the app.</li>
                <li><b>Media Messages: </b> Encrypted images sent as media messages are temporarily stored on a relay server for up to two weeks to facilitate message delivery. After this period, they are automatically deleted. The encryption ensures that these images cannot be viewed by anyone other than the sender and intended recipient(s), even while stored on our servers.</li>
            </ul>
        <p><b>5. Data Security: </b>We employ robust security measures to protect the images stored on our servers. Our security protocols are designed to prevent unauthorized access to or disclosure of user data.</p>

          <p><b>6. User Consent and Control:</b></p>

          <ul>
              <li>Users have full control over the images they choose to upload as profile pictures or send as media messages.</li>
              <li>By using our app, users consent to the collection, use, and storage of their images as outlined in this privacy policy.</li>
          </ul>
      <h3>Information we may share</h3>
      <p>We work with third parties to provide some of our Services. For example, our Third-Party Providers send a verification code to your phone number when you register for our Services. These providers are bound by their Privacy Policies to safeguard that information. If you use other Third-Party Services like YouTube, Spotify, Giphy, etc. in connection with our Services, their Terms and Privacy Policies govern your use of those services.</p>
      
      <h3>Account deletion</h3>
      <p>When you opt for deleting your account, through the "Delete account" button in the application's settings, we erase all your personal information: phone number, profile name, avatar</p>

      <h3>Service Usage Guidelines</h3>
      <p><strong>Adherence to Terms and Policies</strong>: You are required to utilize our Services in compliance with our established Terms and posted guidelines. Should your account be deactivated due to a breach of our Terms, re-creating an account without our explicit approval is prohibited.</p>
  
      <h3>Obligations for Legal and Appropriate Use</h3>
      <p><strong>Purpose of Use</strong>: Our Services are intended solely for lawful, authorized, and appropriate use. You are prohibited from using (or aiding others in using) our Services in a manner that:</p>
      <ul>
          <li>Infringes upon the rights of Wispr, our users, or any third party, encompassing violations of privacy, publicity, intellectual property, or other proprietary rights.</li>
          <li>Entails sending unlawful or prohibited communications such as mass messaging, automated messaging, and automated calling.</li>
      </ul>
      <p><strong>Prohibited Actions</strong>: You, or anyone through your assistance, must not engage in accessing, utilizing, altering, sharing, transferring, or exploiting our Services in unauthorized ways, or in manners detrimental to our company, our services, or systems. For instance, it's forbidden to:</p>
      <ul>
          <li>Attempt or succeed in gaining unauthorized access to our Services or systems.</li>
          <li>Disrupt the proper functioning or performance of our Services.</li>
          <li>Create accounts for our Services through unauthorized or automated methods.</li>
          <li>Unlawfully gather information about our users.</li>
      </ul>
  
      <h3>Exclusion of Emergency Services</h3>
      <p><strong>Emergency Services Unavailability</strong>: It is important to note that our Services do not provide connections to emergency service entities such as police, fire departments, hospitals, or other public safety agencies. Ensure that you have alternative means to reach emergency service providers, such as via mobile or landline telephones.</p>
  
      <h3>Interaction with Third-party Services</h3>
      <p><strong>Usage of External Services</strong>: Our Services may facilitate your interaction with or access to third-party websites, applications, content, and other products and services. Remember, when you engage with third-party services, their own terms and privacy policies apply to your use of those services.</p>
    </div>
  );
}

export default App;
